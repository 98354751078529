import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    // Verifica se há um hash na URL
    const path = location.pathname === "/" ? "home" : location.pathname.substring(1);
    const hash = location.hash.substring(1);
    const idToScroll = hash || path; // Usa o hash se estiver presente

    const element = document.getElementById(idToScroll);
    
    if (element) {
      smoothScrollTo(element, 300);
    }
  }, [location]);

  const smoothScrollTo = (element, duration) => {
    const targetPosition = element.getBoundingClientRect().top + window.scrollY - 45;
    const startPosition = window.scrollY;
    const distance = targetPosition - startPosition;
    let startTime = null;

    const animation = (currentTime) => {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const run = ease(timeElapsed, startPosition, distance, duration);
      window.scrollTo(0, run);
      if (timeElapsed < duration) requestAnimationFrame(animation);
    };

    const ease = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    requestAnimationFrame(animation);
  };

  return null;
}

export default ScrollToTop;

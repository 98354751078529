import { useState, useEffect } from "react";

function useOnScreen(refs) {
  const [visibilityStates, setVisibilityStates] = useState(
    refs.map(() => [false, false])
  );

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry, index) => {
        if (entry.isIntersecting) {
          setVisibilityStates((prevStates) => {
            const newStates = [...prevStates];
            newStates[index][0] = true;
            if (!newStates[index][1]) {
              newStates[index][1] = true;
            }
            return newStates;
          });
        }
      });
    });

    refs.forEach((ref, index) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      refs.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [refs]);

  return visibilityStates;
}

export default useOnScreen;

import React, { useRef } from "react";
import { useSpring, animated } from "react-spring";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaste,
  faBoxesStacked,
} from "@fortawesome/free-solid-svg-icons";

import useOnScreen from "../index/useOnScreen";
import useIsMobile from "../index/useIsMobile";

function Partners() {
  const refs = [useRef(), useRef()];
  const visibilityStates = useOnScreen(refs);
  const isMobile = useIsMobile();

  const animations = [
    useSpring({
      opacity: visibilityStates[0][1] ? 1 : 0,
      transform: isMobile
        ? "translateX(0)"
        : visibilityStates[0][1]
        ? "translateX(0)"
        : "translateX(120px)",
      config: { tension: 180, friction: 60 },
      delay: visibilityStates[0][1] ? 0 : 0,
    }),
    useSpring({
      opacity: visibilityStates[1][1] ? 1 : 0,
      transform: isMobile
        ? "translateX(0)"
        : visibilityStates[1][1]
        ? "translateX(0)"
        : "translateX(120px)",
      config: { tension: 180, friction: 60 },
      delay: visibilityStates[1][1] ? 400 : 0,
    }),
  ];

  return (
    <div className="container-fluid partners">
      <div className="container">
        <div className="row">
          <animated.div ref={refs[0]} style={animations[0]} className="title">
            Nossos Parceiros
          </animated.div>
          <div className="icons">
            <div className="col-12 col-lg-4">
              <FontAwesomeIcon
                icon={faPaste}
                style={{ color: "#d27826" }}
              />
              <div className="title">Reguladoras de Sinistros</div>
            </div>
            <div className="col-12 col-lg-4">
              <FontAwesomeIcon
                icon={faBoxesStacked}
                style={{ color: "#d27826" }}
              />
              <div className="title">Agentes de Carga</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partners;

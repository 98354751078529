import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import { useSpring, animated } from "react-spring";
import useOnScreen from "../index/useOnScreen";
import useIsMobile from "../index/useIsMobile";

import aereo from "../content/services/aereo.webp";
import maritimo from "../content/services/maritimo.webp";
import drone from "../content/services/drone.webp";
import rodoviario from "../content/services/rodoviario.webp";

function Services() {
  const refs = [useRef()];
  const visibilityStates = useOnScreen(refs);
  const isMobile = useIsMobile();

  const animations = [
    useSpring({
      opacity: visibilityStates[0][1] ? 1 : 0,
      transform: isMobile ? "translateX(0)" : (visibilityStates[0][1] ? "translateX(0)" : "translateX(120px)"),
      config: { tension: 180, friction: 60 },
    }),
  ];

  const images = [
    {
      src: aereo,
      alt: "Vistoria Aérea",
      title: "Aéreo",
    },
    {
      src: rodoviario,
      alt: "Vistoria Rodoviária",
      title: "Rodoviário",
    },
    {
      src: maritimo,
      alt: "Vistoria Marítima",
      title: "Marítimo",
    },
    {
      src: drone,
      alt: "Gerenciamento de Drones",
      title: "Drone",
    },
  ];

  const settings = {
    loop: true,
    autoplay: {
      delay: 1500,
      disableOnInteraction: false,
    },
    slidesPerView: 4,
    spaceBetween: 20,
    breakpoints: {
      998: {
        slidesPerView: 4,
      },
      762: {
        slidesPerView: 2,
      },
      0: {
        slidesPerView: 1,
      },
    },
  };

  const items = images.map((image, index) => (
    <SwiperSlide key={index}>
      <div>
        <img src={image.src} alt={image.alt} />
      </div>
      <div className="title">{image.title}</div>
    </SwiperSlide>
  ));

  return (
    <div className="container-fluid services" id="services">
      <div className="container">
        <div className="row">
          <animated.div ref={refs[0]} style={animations[0]} className="title">
            Nossos Serviços
          </animated.div>
          <Swiper modules={[Autoplay]} {...settings}>
            {items}
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default Services;

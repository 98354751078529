import React, { useState } from "react";
import useIsMobile from "../index/useIsMobile";

import logo from "../content/logo/logo.webp";
import bgVideo from "../content/bg_introduction.webm";
import bgImage from "../content/bg_introduction.webp";

function Introduction() {
  const isMobile = useIsMobile();

  const [videoLoaded, setVideoLoaded] = useState(false);

  const handleVideoLoaded = () => {
    setVideoLoaded(true);
  };

  return (
    <div className="container-fluid introduction">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-4">
            <div>
              <img className="logo" src={logo} alt="Logo" />
            </div>
            <div className="subtitle">
              Quantificando dados e impulsionando informações
            </div>
          </div>
          <div className="col-12 col-lg-8">
            <div className="text">
              Garantindo excelência e segurança em cada inspeção.
            </div>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://wa.me/5592993343702?text=Olá, gostaria de mais informações"
            >
            <div className="btn btn-warning contact">FALE CONOSCO</div>
            </a>
          </div>
        </div>
      </div>
      <div className="video-container">
        {!videoLoaded && (
          <img className="img-bg" src={bgImage} alt="Fundo de introdução" />
        )}
        {!isMobile && (
          <video
            className={`introduction-bg ${videoLoaded ? "d-flex" : "d-none"}`}
            src={bgVideo}
            muted
            loop
            autoPlay
            preload="auto"
            onLoadedData={handleVideoLoaded}
          ></video>
        )}
      </div>
    </div>
  );
}

export default Introduction;

import { Link } from "react-router-dom";

function Cta() {
  return (
    <div className="container-fluid cta">
      <div className="container">
        <div className="row">
          <div className="title">Precisa de uma Vistoria Profissional?</div>
          <div className="subtitle">
            Entre em contato com a 2M Surveyor para agendar sua vistoria com
            especialistas certificados. Garantimos qualidade e precisão em
            nossos serviços.
          </div>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://wa.me/5592993343702?text=Olá, gostaria de mais informações"
          >
            <div className="btn btn-light">AGENDAR VISTORIA</div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Cta;

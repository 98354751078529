import Introduction from "../utils/home/Introduction";
import Services from "../utils/home/Services";
import PlusServices from "../utils/home/PlusServices";
import Partners from "../utils/home/Partners";
import Cta from "../utils/home/Cta";
import RegionsOperations from "../utils/home/RegionsOperations";

function Home() {
  return (
    <div className="home">
      <Introduction />
      <Services />
      <PlusServices />
      <Partners />
      <RegionsOperations />
      <Cta />
    </div>
  );
}

export default Home;

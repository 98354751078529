import React, { useRef } from "react";
import { useSpring, animated } from "react-spring";
import useOnScreen from "../index/useOnScreen";
import useIsMobile from "../index/useIsMobile";

import areas_maritima from "../content/services/areas_maritima.webp";
import areas_rodoviaria from "../content/services/areas_rodovia.webp";
import areas_aerea from "../content/services/areas_aerea.webp";
import areas_drone from "../content/services/areas_drone.webp";

function PlusServices() {
  const refs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];
  const visibilityStates = useOnScreen(refs);
  const isMobile = useIsMobile();
  const animations = [
    useSpring({
      opacity: isMobile ? 1 : visibilityStates[0][1] ? 1 : 0,
      transform: isMobile
        ? "translateY(0)"
        : visibilityStates[0][1]
        ? "translateY(0)"
        : "translateY(220px)",
      config: { tension: 180, friction: 60 },
    }),
    useSpring({
      opacity: isMobile ? 1 : visibilityStates[1][1] ? 1 : 0,
      transform: isMobile
        ? "translateY(0)"
        : visibilityStates[1][1]
        ? "translateY(0)"
        : "translateY(220px)",
      config: { tension: 180, friction: 60 },
    }),
    useSpring({
      opacity: isMobile ? 1 : visibilityStates[2][1] ? 1 : 0,
      transform: isMobile
        ? "translateY(0)"
        : visibilityStates[2][1]
        ? "translateY(0)"
        : "translateY(220px)",
      config: { tension: 120, friction: 40 },
    }),
    useSpring({
      opacity: isMobile ? 1 : visibilityStates[3][1] ? 1 : 0,
      transform: isMobile
        ? "translateY(0)"
        : visibilityStates[3][1]
        ? "translateY(0)"
        : "translateY(220px)",
      config: { tension: 120, friction: 40 },
    }),
    useSpring({
      opacity: isMobile ? 1 : visibilityStates[4][1] ? 1 : 0,
      transform: isMobile
        ? "translateY(0)"
        : visibilityStates[4][1]
        ? "translateY(0)"
        : "translateY(220px)",
      config: { tension: 120, friction: 40 },
    }),
    useSpring({
      opacity: visibilityStates[5][1] ? 1 : 0,
      transform: isMobile
        ? "translateY(0)"
        : visibilityStates[5][1]
        ? "translateY(0)"
        : "translateY(220px)",
      config: { tension: 120, friction: 40 },
    }),
  ];

  return (
    <div className="container-fluid plus-services">
      <div className="container">
        <div className="row">
          <animated.div
            ref={refs[0]}
            style={animations[0]}
            className="col-12 col-lg-6 aerea"
          >
            <h2>Vistorias Modal Aéreo</h2>
            <div className="logo">
              <img src={areas_aerea} alt="Área aerea" />
            </div>
            <ul>
              <li>
                <strong>Atendimento de Sinistros</strong>: Doméstico, importação
                e exportação
              </li>
              <li>
                <strong>Assistência a Descarga</strong>: Zonas alfandegadas (
                primária e secundária)
              </li>
            </ul>
          </animated.div>
          <animated.div
            ref={refs[1]}
            style={animations[1]}
            className="col-12 col-lg-6 rodoviaria"
          >
            <h2>Vistorias Modal Rodoviário</h2>
            <div className="logo">
              <img src={areas_rodoviaria} alt="Área rodoviária" />
            </div>
            <ul>
              <li>
                <strong>Atendimento de sinistros</strong>: Cargas secas e
                reefers.
              </li>
              <li>
                <strong>S.O.S</strong>: Tombamento.
              </li>
            </ul>
          </animated.div>
          <animated.div
            ref={refs[2]}
            style={animations[2]}
            className="col-12 col-lg-6 maritima"
          >
            <h2>Vistorias Modal Marítimo</h2>
            <div className="logo">
              <img src={areas_maritima} alt="Área marítima" />
            </div>
            <ul>
              <li>
                <strong>Atendimento de Sinistros</strong>: Cabotagem/importação
                e exportação.
              </li>
              <li>
                <strong>Assistência à Descarga</strong>: Zonas alfandegadas
                (primaria e secundária)
              </li>
              <li>
                <strong>Bunker Survey</strong>: Quantificação de produtos de
                consumo de navios, incluindo Fuel oil e Diesel.
              </li>
              <li>
                <strong>Draft Survey</strong>: Quantificação de cargas secas.
              </li>
              <li>
                <strong>Acompanhamento de Descargas de Navios e Balsas</strong>:
                Monitoramento detalhado das operações de descarga.
              </li>
              <li>
                <strong>
                  Inspeções em Navios e Balsas para Carregamento Diversos
                </strong>
                : Avaliação das condições de embarque para diversos tipos de
                cargas.
              </li>
              <li>
                <strong>Condições de Navios</strong>: Inspeção completa do navio
                para averiguação de avarias em Delivery ou Redelivery.
              </li>
            </ul>
          </animated.div>
          <animated.div
            ref={refs[3]}
            style={animations[3]}
            className="col-12 col-lg-6 drone"
          >
            <h2>Inspeções com Drone</h2>
            <div className="logo">
              <img src={areas_drone} alt="Inspeções e vistorias com Drones" />
            </div>
            <ul>
              <li>Registros de operações de Heavy Lift.</li>
              <li>Registros de S.O.S</li>
            </ul>
          </animated.div>
        </div>
      </div>
      <div className="plus-services-bg"></div>
    </div>
  );
}

export default PlusServices;

import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faWhatsapp,
  faInstagram,
  faTelegram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

import logo from "../content/logo/logo.webp";

function Menu() {
  const location = useLocation();
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState("");
  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
    const currentPath = location.pathname + location.hash; // Inclui o hash
    setActivePage(currentPath === "" ? "" : currentPath);
  }, [location]);

  const navLinks = [
    { path: "/", label: "Página Inicial" },
    { path: "/#services", label: "Serviços" },
    {
      path: "/about",
      label: "Sobre",
    },
    {
      path: "",
      label: "Contato",
      submenu: {
        Whatsapp: {
          icon: faWhatsapp,
          url: "https://wa.me/5592993343702?text=Olá, gostaria de mais informações sobre as vistorias",
        },
        Telefone: { icon: faPhone, url: "tel:+5592993443702" },
        Email: { icon: faEnvelope, url: "mailto:2mrepcomercio@gmail.com" },
        Telegram: { icon: faTelegram, url: "https://t.me/2msurveyor" },
        Instagram: {
          icon: faInstagram,
          url: "https://www.instagram.com/2msurveyor",
        },
        Linkedin: {
          icon: faLinkedin,
          url: "https://www.linkedin.com/in/2msurveyor",
        },
      },
    },
  ];

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed); // Alterna entre colapsado e não colapsado
  };

  const handleLinkClick = (link) => {
    if (link.submenu) {
      // Se o link tiver um submenu, não faz nada e não colapsa
      return;
    }

    if (!link.submenu) {
      setIsCollapsed(true); // Colapsa o menu apenas se não for pai de submenu
    }

    // Se o link contiver um hash (por exemplo, #section)
    if (link.path.includes("#")) {
      const [basePath, sectionId] = link.path.split("#");

      // Se a rota atual já for a mesma, apenas faça scroll suave
      if (location.pathname === basePath) {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
          setActivePage(link.path);
        }
      } else {
        // Caso a rota seja diferente, navega primeiro e então faz o scroll
        navigate(basePath); // Navega até a página base

        setTimeout(() => {
          const element = document.getElementById(sectionId);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
          setActivePage(link.path); // Atualiza o estado de página ativa
        }, 100); // Pequeno atraso para garantir que o DOM tenha sido atualizado
      }
    } else {
      // Para links sem hash, apenas navegue
      navigate(link.path);
      setActivePage(link.path);
    }
  };

  return (
    <nav className="navbar navbar-expand-lg fixed-top">
      <div className="container-fluid">
        <Link className="navbar-brand" to={"/"}>
          <img src={logo} alt="Logo" className="logo" />
          <div className="logo-box"></div>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse} // Alterna o estado do colapso
          aria-expanded={!isCollapsed}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`collapse navbar-collapse ${!isCollapsed ? "show" : ""}`}
          id="navbarNav"
        >
          {navLinks.map((link) => (
            <ul key={link.path} className="navbar-nav">
              <li className={`nav-item ${link.submenu ? "dropdown" : ""}`}>
                <Link
                  className={`nav-link ${
                    link.submenu ? "dropdown-toggle" : ""
                  } ${activePage === link.path ? "active" : ""}`} // Verifica o estado activePage
                  to={link.path}
                  data-nav-link
                  onClick={(e) => handleLinkClick(link, e)}
                  {...(link.submenu && {
                    role: "button",
                    "data-bs-toggle": "dropdown",
                    "aria-expanded": "false",
                  })}
                >
                  {link.label}
                  <div className="box-line">
                    <div className="line"></div>
                  </div>
                  {link.submenu && <span className="triangle-icon"></span>}
                </Link>
                {link.submenu && (
                  <ul className="dropdown-menu">
                    {Object.keys(link.submenu).map((key) => {
                      const className = key.toLowerCase(); // Converte o nome para minúsculas

                      return (
                        <li key={key} className={`dropdown-item ${className}`}>
                          {link.submenu[key].url.startsWith("http") ? (
                            // Link externo
                            <a
                              href={link.submenu[key].url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="dropdown-link"
                              onClick={() => setIsCollapsed(true)} // Fechar o menu ao clicar
                            >
                              <FontAwesomeIcon
                                icon={link.submenu[key].icon}
                                size="lg"
                              ></FontAwesomeIcon>
                              {key}
                            </a>
                          ) : (
                            // Link interno
                            <Link
                              to={link.submenu[key].url}
                              onClick={(e) => {
                                e.preventDefault();
                                handleLinkClick(key, e); // Mantém a lógica de navegação interna
                              }}
                              className="dropdown-link"
                            >
                              <FontAwesomeIcon
                                icon={link.submenu[key].icon}
                                size="lg"
                              ></FontAwesomeIcon>
                              {key}
                            </Link>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            </ul>
          ))}
        </div>
      </div>
    </nav>
  );
}

export default Menu;

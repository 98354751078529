import logo from "../content/logo/logo.webp";
import bg from "../content/footer.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faWhatsapp,
  faInstagram,
  faTelegram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <div className="container-fluid footer">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-3">
            <div className="logo col-6 col-lg-7">
              <img src={logo} alt="Logo 2M Surveyor" />
            </div>
            <div className="col-12 address">
              <div>Rua Magalhães Barata, 4</div>
              <div>69073-166, Manaus/AM</div>
              <div>CNPJ: 28.354.840/0001-71</div>
            </div>
          </div>
          <div className="col-12 col-lg-6 d-none d-lg-block">
            <span className="quote">"</span>
            Nossa missão é garantir excelência e segurança em cada inspeção.
            Utilizamos técnicas avançadas para avaliações rigorosas. Conte
            conosco para proteger seus interesses e garantir operações seguras.
            Sua satisfação e confiança são nossas prioridades.
            <span className="quote">"</span>
          </div>
          <div className="col-12 col-lg-3">
            <div className="title">Redes Sociais</div>
            <div>
              <div className="whatsapp">
                <a
                  href="https://wa.me/5592993343702?text=Olá, gostaria de mais informações sobre as vistorias"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faWhatsapp}
                    size="2xl"
                    style={{ color: "#51f543" }}
                  />
                </a>
              </div>
              <div className="telefone">
                <a
                  href="tel:+5592993443702"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faPhone}
                    size="2xl"
                    style={{ color: "#d2d2d2" }}
                  />
                </a>
              </div>
              <div className="email">
                <a
                  href="mailto:operacional@2msurveyor.com.br"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    size="2xl"
                    style={{ color: "#ff4448" }}
                  />
                </a>
              </div>
              <div className="telegram">
                <a
                  href="https://t.me/2msurveyor"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faTelegram}
                    size="2xl"
                    style={{ color: "#304d81" }}
                  />
                </a>
              </div>
              <div className="instagram">
                <a
                  href="https://www.instagram.com/2msurveyor"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    size="2xl"
                    style={{ color: "#cc62ff" }}
                  />
                </a>
              </div>
              <div className="linkedin">
                <a
                  href="https://www.linkedin.com/in/2msurveyor"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    size="2xl"
                    style={{ color: "#4ba0d3" }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="image-bg">
        <img src={bg} alt="Imagem de Fundo da 2M Vistorias" />
      </div>
    </div>
  );
}

export default Footer;

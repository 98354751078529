import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Home from "../../pages/Home";
import About from "../../pages/About";

function Body() {
  return (
    <div>
      <ScrollToTop />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <React.Fragment>
              <div id="home">
                <Home />
              </div>
            </React.Fragment>
          }
        />
        <Route
          exact
          path="/about"
          element={
            <React.Fragment>
              <div id="about">
                <About />
              </div>
            </React.Fragment>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}

export default Body;

import "./utils/App.css";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import Menu from "./utils/index/Menu.js";
import Footer from "./utils/index/Footer.js";
import Body from "./utils/index/Body.js";

function App() {
  return (
    <Router>
      <Menu />
      <Body />
      <Footer />
    </Router>
  );
}

export default App;

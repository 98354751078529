import logo from "../utils/content/logo/logo.webp"

function About() {
  return (
    <div className="container-fluid about">
      <div className="container">
        <div className="row">
            <div className="logo col-5 col-lg-2">
                <img src={logo} alt="Logo na página sobre nós" />
            </div>
          <div className="title">Nossa História</div>
          <div class="text">
            A 2M é uma empresa independente de inspeções, tecnicamente
            qualificada e habilitada para oferecer gestão, perícia de avarias,
            análise e regulação de sinistros. Nosso foco é fornecer soluções nas
            áreas de MARINE, RE, SURVEYOR e SOS DE CARGA. Com 7 anos de
            experiência no mercado, a 2M nasceu com a expertise acumulada de 19
            anos dos nossos gestores, que possuem vasta vivência no setor de
            seguros.
            <br />
            <br />
            Nossa missão é garantir qualidade e eficiência em todos os serviços
            prestados, utilizando tecnologia de ponta e métodos inovadores.
            Nossa equipe é composta por profissionais altamente capacitados,
            constantemente treinados para acompanhar as melhores práticas do
            mercado.
            <br />
            <br />
            Em MARINE, oferecemos soluções para a gestão de riscos marítimos,
            desde a inspeção de embarcações até a análise de avarias e
            sinistros. Nossos serviços em RE incluem a avaliação e regulação de
            sinistros complexos. Como SURVEYORS, realizamos inspeções detalhadas
            e emitimos relatórios técnicos. O serviço SOS DE CARGA oferece
            suporte imediato em emergências, minimizando prejuízos e garantindo
            a segurança das cargas.
            <br />
            <br />
            Na 2M, acreditamos que a excelência é alcançada através de trabalho
            árduo, compromisso com a qualidade e um atendimento ao cliente
            excepcional. Entre em contato conosco para saber mais sobre como
            podemos ajudar sua empresa a alcançar seus objetivos.
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;

import mapa from "../content/north/map.webp";

function RegionsOperations() {
  return (
    <div className="container-fluid mapa">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="title">Regiões de Operações</div>
          </div>
          <div className="col-12 col-lg-8">
            <div className="mapa-img">
              <img
                src={mapa}
                alt="Regiões em que atendemos, Amazonas, Acre e Roraima"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegionsOperations;
